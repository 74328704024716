<template>
  <v-container class="pa-0" fluid>
    <Carousel />
    <CardNuestraEmpresa />
    <ServiciosEspecializados />
    <PreguntaFrecuentes />
  </v-container>
</template>

<style></style>
<script>
import { defineComponent } from "@vue/composition-api";
import Carousel from "./components/Carousel.vue";
import { mapGetters, mapActions } from "vuex";
import CardNuestraEmpresa from "./components/CardNuestraEmpresa.vue";
import ServiciosEspecializados from "./components/ServiciosEspecializados.vue";
import PreguntaFrecuentes from "./components/PreguntaFrecuentes.vue";

export default defineComponent({
  components: {
    Carousel,
    CardNuestraEmpresa,
    ServiciosEspecializados,
    PreguntaFrecuentes,
  },
  created() {},
  computed: {
    ...mapGetters(["getProducts", "getCategories"]),
  },
  methods: {
    ...mapActions(["updateProducts", "updateCategories"]),
  },

  async mounted() {
    let data = await Promise.all([this.updateProducts(), this.updateCategories()]);
  },
});
</script>

