<template>
  <div class="container-banner-corp content">
    <div>
      <img
        style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
        src="../../../assets/line-horizontal.png"
        alt=""
      />
      <span class="sub-menu"> Bienvenidos a Higher Up Coaching </span>
    </div>
    <h1 class="mt-8 color-white title-class">Coaching Corporativo</h1>
    <v-btn
      @click="scrollToSection('mainProduct')"
      class="mt-16"
      color="secondary"
    >
      Explora nuestros productos
    </v-btn>
  </div>
</template>

<style>
.sub-menu {
  color: #658dc0;
  font-style: italic;
}

.container-banner-corp {
  background-image: url("../../../assets/fondo-coaching-coporativo-banner.png");
  background-size: cover;
  background-color: white;
}

.color-white {
  color: white;
}

.title-class {
  font-size: 3rem;
}

.content {
  display: flex;

  text-align: start;
  flex-direction: column;
  padding-left: 10rem;
  align-items: flex-start;
  justify-content: center;
  height: 382px;
  width: 100%;
}
</style>

<script>
export default {
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth"
        });
      }
    }
  }
};
</script>
