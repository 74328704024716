<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div style="justify-content: flex-start; display: flex; margin: auto; font-weight: 400; font-size: 30px; color: #101D30; margin: 1rem">
      <h2>Información de pago</h2>
    </div>
    <v-container>
      <v-row style="margin:1rem">
        <div style="display: flex; margin: auto; justify-content: space-between; width: 100%;">
          <div style="margin-right: 5px; width: 48%;">
            <input
              v-model="first_name"
              type="text"
              maxlength="10"
              required
              placeholder="Nombre"
              style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; background: #EEEEEE;"
            />
          </div>
          <div style="margin-left: 5px; width: 48%;">
            <input
              v-model="last_name"
              type="text"
              maxlength="10"
              required
              placeholder="Apellido"
              style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; background: #EEEEEE;"
            />
          </div>
        </div>
      </v-row>
      <v-row style="margin:1rem">
        <div style="width: 100%;">
          <input
            v-model="email"
            type="email"
            required
            placeholder="E-mail"
            style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; background: #EEEEEE;"
          />
        </div>
      </v-row>
      <v-row style="margin:1rem">
        <div style="width: 100%;">
          <input
            v-model="phone"
            type="tel"
            maxlength="18"
            required
            placeholder="Phone Number"
            style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; background: #EEEEEE;"
          />
        </div>
      </v-row>
      <v-row style="margin:1rem" v-if="items.length > 0">
        <div style="width: 100%;">
          <select
            v-model="country"
            required
            style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; background: #EEEEEE;"
          >
            <option style="color: #EEEEEE !important;" value="" disabled>Selecciona tu país</option>
            <option v-for="item in items" :key="item.code" :value="item.country">{{ item.country }}</option>
          </select>
        </div>
      </v-row>
      <v-row style="margin:1rem">
        <v-container>
          <v-row>
            <span style="font-size: 18px; font-family: Montserrat; font-weight: 600; font-size: 18px; color: #686868">Opciones de pago</span>
          </v-row>
          <v-row>
            <v-radio-group v-model="radioGroup">
              <v-radio v-for="n in radioLabels" :key="n" :label="n" :value="n"></v-radio>
            </v-radio-group>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import countries from "./../../../model/countries.json";
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    valid: true,
    first_name: "",
    last_name: "",
    phone: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    country: "",
    items: [],
    checkbox1: false,
    radioGroup: 1,
    radioLabels: [],
  }),

  computed: {
    ...mapGetters(["getPaymentMethods"]),
  },
  methods: {
    ...mapActions(["updatePaymentMethods"]),
    validate() {
      if (this.$refs.form.validate()) {
        // Emitir el evento formValidated con los datos del formulario
        this.$emit("validForm", {
          billing: {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone,
            country: this.country,
          },
          paymentOption: this.radioLabels[this.radioGroup],
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  async mounted() {
    await countries.forEach((c) => {
      this.items.push({ country: c.country, code: c.code });
    });
    await this.updatePaymentMethods();
    this.getPaymentMethods.data.forEach((p, index) => {
      if (index <= 1) {
        this.radioLabels.push(p.title);
      }
    });
    console.log(this.getPaymentMethods);
    console.log(this.items);
  },
};
</script>
