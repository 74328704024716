<template>
  <v-carousel
    style="height: 80%; width: 100%"
    class="container-general-c"
    hide-delimiters
  >
    <v-carousel-item
      src="../../../assets/carousel-1.png"
      cover
      class="container-carousel-item"
    >
      <div class="container-carousel-content">
        <div>
          <img
            style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
            src="../../../assets/line-horizontal.png"
            alt=""
          />
          <span class="sub-menu"> TEST PERSONAL </span>
        </div>
        <h1 class="color-white title-class">
          Adquiere <span class="sub-menu">Nuestro</span> Test Disc Personal
        </h1>
        <div class="carouselText">
          <p class="color-white">
            Si compras tu test te obsequiamos la interpretación del mismo
            directamente de un experto.
          </p>
        </div>
        <v-btn color="secondary"> COMPRAR </v-btn>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<style lang="scss">
.container-general-c {
  width: 100%;
  margin-top: 0;
}

.sub-menu {
  color: #658dc0;
}

.container-carousel-item {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.color-white {
  color: white;
}

.title-class {
  font-size: 3rem;
}

.carouselText p {
  width: 25rem;
}

.container-carousel-content {
  text-align: start;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10rem;
  margin-left: 10rem;
  width: 40rem;
  height: 10rem !important;
}
</style>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "../../../assets/carousel-1.png"
        },
        {
          src: "../../../assets/carousel-1.png"
        },
        {
          src: "../../../assets/carousel-1.png"
        },
        {
          src: "../../../assets/carousel-1.png"
        }
      ]
    };
  }
};
</script>
