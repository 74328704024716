<template>
  <div>
    <v-container class="pb-0">
      <v-row style="margin: 0rem 0rem 1rem 0rem;">
        <h2>
          Registro de Usuario
        </h2>
      </v-row>
      <div style="display: block; justify-content: center; margin: auto">
        <div cols="4" class="py-0 px-1" >
          <o-text-field style="font-family: montserrat;" label="Name" v-model="user.displayName" />
        </div>
        <div cols="4" class="py-0 px-1">
          <o-email-field style="font-family: montserrat;" v-model="user.email" />
        </div>
        <div cols="4" class="py-0 px-1">
          <o-password-field  style="font-family: montserrat;" v-model="user.password" />
        </div>
      </div>
      <v-row>
        <v-col cols="12">
          <v-btn style="font-family: montserrat; width: 100%;" color="secondary" @click="signUp">
            Registrarse
          </v-btn>
        </v-col>
      </v-row>
      <v-row >
        <div style="display: flex; justify-content: center; width:100%; margin: auto; ">
          <div>
            <p>No tienes cuenta? Regístrate aquí: </p>
          </div>
          &nbsp;
          <div>
            <p class="register-button-r" style="cursor: pointer"> Registrarse</p>
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import authMixin from "@/mixins/authMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import messages from "@/plugins/messages";
import { mapActions } from "vuex";

export default defineComponent({
  name: "SignUp",
  mixins: [authMixin, messageDialogMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
        displayName: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions(["sendAccountCreated"]),
    validateForm() {
      if (!this.user.displayName || !this.user.email || !this.user.password) {
        this.errorDialog("Todos los campos son obligatorios.");
        return false;
      }
      // Puedes agregar más validaciones aquí si es necesario
      return true;
    },
    async signUp() {
      if (this.validateForm()) {
        const user_account = {
          email: this.user.email,
          password: this.user.password,
          displayName: this.user.displayName,
        };
        console.log(user_account);
        this.loading = true;
        this.$store
          .dispatch("signUpNest", user_account)
          .then(async () => {
            await this.sendAccountCreated(user_account);
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errorDialog(error.message);
          });
      }
    },
  },
});
</script>

<style scoped>
.register-button-r:hover{
  text-decoration: underline;
}

</style>
