import firebase from "firebase";
import firebaseConfig from "@/config/firebase";
import axios from "axios";

import f from "@/plugins/formatter";
import messages from "@/plugins/messages";
import { getError } from "@/plugins/messages";
import cloneDeep from "lodash.clonedeep";
// TODO: Refactor 'instance' name
const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL,
});

function castUser(firebaseUser) {
  return {
    id: firebaseUser.uid,
    email: firebaseUser.email,
    displayName: firebaseUser.displayName,
  };
}

export default {
  getCredentials(currentPassword) {
    const user = this.getCurrentUser();
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  },
  getCurrentUser() {
    return firebase.auth().currentUser;
  },
  signIn: (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (response) => {
          const user = castUser(response.user);
          console.log(response.user);
          resolve({ user });
        })
        .catch((error) => reject(getError(error)));
    });
  },
  signInNest: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(data);
        const response = await axios.post(
          "https://apitest.higherupcoaching.com/auth/login",
          data,
          {
            headers: {},
          }
        );
        console.log(response);
        console.log(response.data);
        return response.data;
      } catch (error) {
        reject(getError(error));
      }
    });
  },
  validateUser: async (data) => {
    try {
      const response = await axios.post(
        "https://apitest.higherupcoaching.com/auth/validate",
        { email: data },
        {
          headers: {},
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      getError(error);
      throw error;
    }
  },
  signOut: () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(getError(error)));
    });
  },
  signUpNest: async (data) => {
    try {
      const response = await axios.post(
        "https://apitest.higherupcoaching.com/auth/register",
        data,
        {
          headers: {},
        }
      );
      // console.log(data)
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  },
  signUp: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await firebase
          .auth()
          .createUserWithEmailAndPassword(data.email, data.password);

        const user = castUser(response.user);
        user.displayName = data.displayName;

        await firebase.auth().currentUser.updateProfile({
          displayName: data.displayName,
        });

        instance
          .post("/session/complete-signup", {
            user,
          })
          .then((completeSignUpResponse) => {
            if (completeSignUpResponse.status === 200) {
              resolve(user);
            } else {
              reject({ message: "Ha ocurrido un error inesperado" });
            }
          })
          .catch(async (error) => {
            console.log(error.response);
            if (error.response.status === 409) {
              await firebase.auth().currentUser.delete();
              reject({ message: messages.errors.accountCreationError });
            } else {
              reject({ message: "Ha ocurrido un error inesperado" });
            }
          });
      } catch (error) {
        reject(getError(error));
      }
    });
  },
  saveBillingInfo(accountInfoId, billingInfo) {
    return new Promise((resolve, reject) => {
      const clonedBillingInfo = cloneDeep(billingInfo);
      clonedBillingInfo.address.country = billingInfo.address.country.code;
      instance
        .post(`/session/account-info/${accountInfoId}/billing-info`, {
          billingInfo: f.objectCamelToSnakeCase(clonedBillingInfo, true),
        })
        .then((response) => {
          if (response.status === 201) {
            resolve();
          } else {
            reject({ message: "Ha ocurrido un error inesperado" });
          }
        })
        .catch((error) => {
          reject(getError(error.response.data));
        });
    });
  },
  forgotPassword: (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((response) => resolve(response))
        .catch((error) => reject(getError(error)));
    });
  },
  verifyChangePasswordCode(code) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then((response) => resolve(response))
        .catch((error) => reject(getError(error)));
    });
  },
  changePassword(code, newPassword) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .confirmPasswordReset(code, newPassword)
        .then((response) => resolve(response))
        .catch((error) => reject(getError(error)));
    });
  },
  changePasswordLogin(currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.getCredentials(currentPassword)
        .then(() => {
          resolve();
          return new Promise((resolve, reject) => {
            firebase
              .auth()
              .currentUser.updatePassword(newPassword)
              .then(() => resolve())
              .catch((error) => reject(getError(error)));
          });
        })
        .catch((error) => reject(getError(error)));
    });
  },
  getAccountInfo(userId) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("account_info")
        .where("users", "array-contains", userId)
        .get()
        .then((response) => {
          if (!response.empty) {
            const doc = response.docs[0];
            if (doc.exists) {
              const data = doc.data();
              resolve({
                id: doc.id,
                purchaseRequired: data.purchase_required,
                customerId: data.stripe_customer_id,
                subscriptionInfo: f.objectSnakeToCamelCase(
                  data.subscription_info,
                  true
                ),
                accountStats: data.account_stats,
                billingInfo: !!data.billing_info,
              });
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => reject(error));
    });
  },
};
