<template>
  <v-container></v-container>
</template>

<style></style>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([])
  },
  async mounted() {}
};
</script>
