<template>
  <div class="super-card-container">
    <div class="card-container-O">
      <div class="check-group">
        <img src="../../../assets/check-icon.svg" alt="" />
        <div>
          <h1 style="text-align: start">Gracias por preferirnos</h1>
          <p style="text-align: start; padding-left: 0.5rem">
            Su compra ha sido exitosa
          </p>
        </div>
      </div>
      <div style="border: 1px solid #658dc0; width: 100%; height: 0rem"></div>
      <v-container v-if="this.getOrderInfo">
        <v-row>
          <v-col>
            <div class="detalles-facturacion">
              <h3>Detalles de la compra</h3>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Nombre: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{
                          this.getOrderInfo.billing.first_name
                            ? this.getOrderInfo.billing.first_name
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Apellido: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{
                          this.getOrderInfo.billing.last_name
                            ? this.getOrderInfo.billing.last_name
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Correo: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{
                          this.getOrderInfo.billing.email
                            ? this.getOrderInfo.billing.email
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Telefono: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{
                          this.getOrderInfo.billing.phone
                            ? this.getOrderInfo.billing.phone
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Pais: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{
                          this.getOrderInfo.billing.country
                            ? this.getOrderInfo.billing.country
                            : ""
                        }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </div>
          </v-col>
          <v-col>
            <div class="detalles-facturacion">
              <v-container>
                <v-row>
                  <v-col>
                    <h3>Orden</h3>
                  </v-col>
                  <v-col> No {{ this.getOrderInfo.number }} </v-col>
                </v-row>
              </v-container>
              <v-card-text style="padding: 0rem 5rem 0rem 5rem">
                <v-container>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Producto: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{ this.getOrderInfo.line_items[0].name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Costo unitario: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{ this.getOrderInfo.line_items[0].price }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="span-title"> Cantidad: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{ this.getOrderInfo.line_items[0].quantity }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <div
                      style="
                        border: 1px solid #658dc0;
                        width: 100%;
                        height: 0rem;
                      "
                    ></div>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="span-title"> Total: </span>
                    </v-col>
                    <v-col>
                      <span class="span-price">
                        {{ this.getOrderInfo.total }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <div
                      style="
                        border: 1px solid #658dc0;
                        width: 100%;
                        height: 0rem;
                      "
                    ></div>
                  </v-row>
                </v-container>
              </v-card-text>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div style="border: 1px solid #658dc0; width: 100%; height: 0rem"></div>
      <div>
        <v-container>
          <v-row>
            <v-col>
              <p>
                Por favor verifica tu email, hemos enviado un correo con los
                detalles de tu orden y la informacion de acceso a tu cuenta
                <strong>Higher up</strong>.
              </p>
            </v-col>
            <v-col>
              <v-btn color="secondary"> Seguir comprando </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      orderId: "",
      methodId: "",
    };
  },
  computed: {
    ...mapGetters(["getOrderInfo"]),
  },
  methods: {
    ...mapActions([
      "sendOrderConfirmation",
      "sendAccountCreated",
      "validateUser",
      "signUpNest",
      "updateOrder",
      "getOrder",
      "finishPayment",
    ]),
    generatePassword() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$";
      let password = "";
      for (let i = 0; i < 8; i++) {
        const index = Math.floor(Math.random() * characters.length);
        password += characters.charAt(index);
      }
      return password;
    },
    getMillisecondsAYearFromNow() {
      const now = new Date();

      now.setFullYear(now.getFullYear() + 1);

      const millisecondsAYearFromNow = now.getTime();

      return millisecondsAYearFromNow;
    },
  },
  async mounted() {
    this.orderId = this.$route.query.orderId;
    this.methodId = this.$route.query.methodId;
    await this.getOrder(this.orderId);
    await this.sendOrderConfirmation(this.getOrderInfo);
    const isRegistered = await this.validateUser(
      this.getOrderInfo.billing.email
    );
    let subscriptionInfo = {
      assessment_quantity: parseInt(
        this.getOrderInfo.line_items[0].sku.split("-")[2]
      ),
      status: "ACTIVE",
      expiresAt: this.getMillisecondsAYearFromNow(),
    };
    let productType = "pack";
    if (!isRegistered) {
      const user_account = {
        email: this.getOrderInfo.billing.email,
        password: this.generatePassword(),
        displayName:
          this.getOrderInfo.billing.first_name +
          this.getOrderInfo.billing.last_name,
      };
      productType = "membership";
      await this.signUpNest(user_account);
      await this.sendAccountCreated(user_account);
    }

    if (this.getOrderInfo.status == "pending") {
      await this.finishPayment({
        subscriptionInfo,
        productType,
        email: this.getOrderInfo.billing.email,
      });
      await this.updateOrder({
        id: this.getOrderInfo.id,
        set_paid: true,
        status: "processing",
      });
    }
  },
};
</script>

<style lang="scss">
.detalles-facturacion {
}

.card-container-O {
  width: 80%;
  justify-content: center;
  display: block;
  margin: auto;
}

.check-group {
  display: flex;
}

.super-card-container {
  background-image: url("../../../assets/background-1.svg");
  background-size: cover;
  width: 100%;
  margin: auto;
  justify-content: center;
  display: flex;
}
</style>
