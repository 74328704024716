<template>
  <v-container class="pa-0" fluid>
    <Banner />
    <NuestraEmpresaUs />
    <BannerNumerico />
    <Carousel />
    <OurTeam />
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Banner from "./components/Banner.vue";
import NuestraEmpresaUs from "./components/NuestraEmpresaUs.vue";
import BannerNumerico from "./components/BannerNumerico.vue";
import Carousel from "./components/Carousel.vue";
import OurTeam from "./components/OurTeam.vue";

export default defineComponent({
  components: {
    Banner,
    NuestraEmpresaUs,
    BannerNumerico,
    Carousel,
    OurTeam
  }
});
</script>
