<template>
  <div class="container-general-p">
    <div class="expansion-panel-img">
      <div class="container-exp-card-2">
        <div class="preguntas-f">
          <div class="faqs">
            <div class="line-horizontal"></div>
            <span style="color: #658dc0; font-style: italic; font-family: 'montserrat';">Escríbenos</span>
          </div>
          <div class="preguntas-frecuentes-title">
            <h1 style="color: white;">Información de contacto</h1>
          </div>
          <div>
            <div class="contact-info">
              <div class="faqs1">
                <img src="../../../assets/map-icon.svg" class="icon" alt="">
                <span class="info-text">
                  La dirección va escrita en este lugar
                </span>
              </div>
              <div class="faqs1">
                <img src="../../../assets/mail-icon.svg" class="icon" alt="">
                <span class="info-text">
                  correo@higher.com
                </span>
              </div>
              <div class="faqs1">
                <img src="../../../assets/phone-icon.svg" class="icon" alt="">
                <span class="info-text">
                  (000) 000 00 00
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="p-f-c-2">
          <div class="p-f-c-img">
            <div class="container-pfcimg">
              <div class="form-data">
                <v-form class="pa-5" v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="firstname" :counter="10" :rules="nameRules" label="Nombre" hide-details required></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="lastname" :counter="10" :rules="nameRules" label="Apellido" hide-details required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="email" :counter="10" :rules="emailRules" label="Email" hide-details required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-container fluid>
                          <v-textarea label="Mensaje" model-value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through." name="input-7-1" variant="filled" auto-grow></v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-btn style="width: 100%" color="secondary">
                      enviar pregunta
                    </v-btn>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.center-div-x {
  display: block;
  margin: auto;
  justify-content: center;
}

.form-data {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-pfcimg {
  width: 100%;
}

.preguntas-frecuentes-title {
  text-align: start;
}

.line-horizontal {
  background-image: url("../../../assets/line-horizontal.png");
  background-size: contain;
  width: 3rem;
  margin-top: 0.6rem;
  margin-right: 1rem;
}

.p-f-c-img {
  margin: auto;
  background-image: url("../../../assets/preguntas-frecuentes-card-2.svg");
  background-size: contain;
  width: 25rem;
}

.preguntas-f {
  background-color: #101d30 !important;
}

.fa-chevron-down {
  color: white !important;
}

.panel-v {
  background-color: #101d30;
}

/* Estilos CSS personalizados */
.custom-header-bg {
  background-color: #101d30 !important;
  color: rgb(255, 255, 255);
}

.custom-content-bg {
  color: white;
  background-color: #101d30 !important;
}

.preguntas-f {
  margin-top: 5rem;
}

.p-f-c-2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-exp-card-2 {
  display: flex;
  margin: auto;
  width: 80%;
}

.faqs {
  margin: auto;
  justify-content: flex-start;
  display: flex;
}

.faqs1:hover{
  *{
    text-decoration: underline;

  }
}
.faqs1 {
  cursor: pointer;
  margin: 0rem 0.5rem 0rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 1rem 0;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0rem;
}

.icon {
  padding: 0.3rem;
}

.info-text {
  font-family: 'montserrat';
  font-size: 0.8rem;
  color: white;
  margin-left: 0.5rem;
}

.expansion-panel-class {
  width: 90%;
  background-color: #101d30;
}

.expansion-panel-img {
  background-size: cover;
  width: 60%;
  margin-top: 2rem;
  background-image: url("../../../assets/preguntas-frecuentes-card.svg");
}

.container-general-p {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 35rem;
  background-size: cover;
  background-position: 0rem 0rem;
  background: white;
}

.forgot-password-button:hover {
  text-decoration: underline;
}
</style>

<script>
export default {
  data() {
    return {
      valid: false,
      firstname: '',
      lastname: '',
      email: '',
      nameRules: [
        v => !!v || 'El nombre es requerido',
        v => (v && v.length <= 10) || 'El nombre debe tener menos de 10 caracteres',
      ],
      emailRules: [
        v => !!v || 'El email es requerido',
        v => /.+@.+\..+/.test(v) || 'El email debe ser válido',
      ],
    };
  },
};
</script>
