<template>
  <v-container class="pa-0" fluid>
    <Banner />
    <ProductCards />
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Banner from "./components/Banner.vue";
import ProductCards from "./components/ProductCards.vue";

export default defineComponent({
  components: {
    Banner,
    ProductCards
  }
});
</script>
