<template>
  <v-container class="pa-0" fluid>
    <Banner />
    <Informacion/>
  </v-container>
</template>

<style></style>
<script>
import { defineComponent } from "@vue/composition-api";
import Banner from "./components/Banner.vue";
import Informacion from "./components/Informacion.vue";
export default defineComponent({
  components: {
    Banner,
    Informacion
  },
  async mounted() {},
  created() {}
});
</script>
