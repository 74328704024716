<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1"> Datos personales </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2"> Opciones de pago </v-stepper-step>

      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
          <div class="form-and-summary">
            <checkout-form class="checkout-form-class" v-model="billingInfo" @validForm="comprar"
              ref="checkoutForm"></checkout-form>
            <order-summary class="order-summary-class" @comprar="validateForm"></order-summary>
          </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-container>
          <div class="form-and-summary">
            <div style="display: flex; margin: auto;
            align-items: center;    width: 100%;

            ">
              <payment-options v-if="step == 2"></payment-options>
            </div>
            <order-summary class="order-summary-class order-display-none"></order-summary>
          </div>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<style lang="scss">
.checkout-form-class {
  margin: 1rem;
}


.order-summary-class {
  margin: 1rem;
  width: 80%;
}
.order-display-none{
  button{
    display: none;
  }
}
.form-and-summary {
  display: flex;
  margin: 5rem 10rem 5rem 10rem !important;
  justify-content: center;
  margin: auto;
}
</style>

<script>
import PaymentOptions from "./components/PaymentOptions.vue";
import CheckoutForm from "./components/CheckoutForm.vue";
import OrderSummary from "./components/OrderSummary.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PaymentOptions,
    CheckoutForm,
    OrderSummary,
  },
  data() {
    return {
      step: 1,
      billingInfo: {},
      paymentOption: null,
    };
  },
  computed: {
    ...mapGetters(["getOrderInfo"]),
  },
  methods: {
    ...mapActions(["updateBilling", "selectPaymentMethod", "updateOrder"]),
    async comprar(data) {
      // lógica para enviar los datos al servidor y procesar el pago
      this.billingInfo = data.billing;
      this.updateBilling(this.billingInfo);
      console.log(this.getOrderInfo);
      await this.updateOrder({
        id: this.getOrderInfo.id,
        billing: this.billingInfo,
      });
      this.selectPaymentMethod(data.paymentOption);
      this.step = 2;
    },
    validateForm() {
      // Acceder a la instancia del componente CheckoutForm y llamar a su método validate
      if (this.$refs.checkoutForm) {
        this.$refs.checkoutForm.validate();
      }
    },
    volver() { },
  },
};
</script>
