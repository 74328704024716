<template>
    <v-container class="pa-0" fluid>
      <OrderCompleted/>
    </v-container>
  </template>
  
  <script>
  import { defineComponent } from "@vue/composition-api";
  import OrderCompleted from "./components/OrderCompleted.vue";
  
  export default defineComponent({
    components: {
      OrderCompleted
    }
  });
  </script>