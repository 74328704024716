<template>
  <div class="container-general-p">
    <div class="expansion-panel-img">
      <div class="container-exp-card-2">
        <div class="preguntas-f">
          <div class="faqs">
            <div class="line-horizontal"></div>
            <span style="color: #658dc0"> FAQ´s </span>
          </div>
          <div class="preguntas-frecuentes-title">
            <h1 style="color: white">Preguntas Frecuentes</h1>
          </div>
          <v-expansion-panels class="expansion-panel-class">
            <v-expansion-panel class="panel-v" v-for="(item, i) in 3" :key="i">
              <v-expansion-panel-header class="custom-header-bg">
                ¿Pregunta de prueba este texto es solo para muestra?
              </v-expansion-panel-header>

              <v-expansion-panel-content class="custom-content-bg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="p-f-c-2">
          <div class="p-f-c-img">
            <div class="container-pfcimg">
              <div class="form-data">
                <v-form class="pa-5">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="firstname"
                          :counter="10"
                          :rules="nameRules"
                          label="Nombre"
                          hide-details
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="lastname"
                          :counter="10"
                          :rules="nameRules"
                          label="Apellido"
                          hide-details
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="email"
                          :counter="10"
                          :rules="emailRules"
                          label="Email"
                          hide-details
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-container fluid>
                          <v-textarea
                            label="Mensaje"
                            v-model="message"
                            name="input-7-1"
                            variant="filled"
                            auto-grow
                          ></v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-btn style="width: 100%" color="secondary"> enviar pregunta </v-btn>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.center-div-x {
  display: block;
  margin: auto;
  justify-content: center;
}

.form-data {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-pfcimg {
  width: 100%;
}

.preguntas-frecuentes-title {
  text-align: start;
}

.line-horizontal {
  background-image: url("../../../assets/line-horizontal.png");
  background-size: contain;
  width: 3rem;
  margin-top: 0.6rem;
  margin-right: 1rem;
}

.p-f-c-img {
  margin: 1rem;
  background-image: url("../../../assets/preguntas-frecuentes-card-2.svg");
  background-size: contain;
  width: 25rem;
}

.preguntas-f {
  background-color: #101d30 !important;
}

.fa-chevron-down {
  color: white !important;
}

.panel-v {
  background-color: #101d30;
}

/* Estilos CSS personalizados */
.custom-header-bg {
  background-color: #101d30 !important;
  color: rgb(255, 255, 255);
}

.custom-content-bg {
  color: white;
  background-color: #101d30 !important;
}

.preguntas-f {
  margin-top: 5rem;
}

.p-f-c-2 {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-exp-card-2 {
  display: flex;
  margin: auto;
  width: 80%;
}

.faqs {
  margin: auto;
  justify-content: flex-start;
  display: flex;
}

.expansion-panel-class {
  width: 90%;
  background-color: #101d30;
}

.expansion-panel-img {
  background-size: cover;
  width: 60%;
  margin-top: 2rem;
  background-image: url("../../../assets/preguntas-frecuentes-card.svg");
}

.container-general-p {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 35rem;
  background-image: url("../../../assets/preguntas-frecuentes.svg");
  background-size: cover;
  background-position: 0rem 0rem;
}
</style>

<script>
export default {
  data: () => ({
    firstname: "",
    lastname: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    message: "",
  }),
};
</script>
