<template>
  <div class="super-card-container">
    <div class="card-container">
      <div>
        <img src="../../../assets/nuestra-empresa.svg" alt="" />
      </div>
      <div class="container-text">
        <img
          style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
          src="../../../assets/line-horizontal.png"
          alt=""
        />
        <span style="color: #658dc0"> SOBRE NOSOTROS</span>
        <p class="text-h4 font-weight-black">Nuestra empresa</p>
        <div>
          <p>
            Higher Up Coaching es una empresa líder en coaching dedicada a
            potenciar a individuos y organizaciones para alcanzar su máximo
            potencial. Con un equipo de coaches experimentados y certificados,
            brindamos soluciones de coaching personalizadas adaptadas a las
            necesidades y metas únicas de nuestros clientes. Ya sea que busques
            crecimiento personal o que desees mejorar el rendimiento de tu
            equipo, Higher Up Coaching está aquí para guiarte en tu camino hacia
            el éxito.
          </p>
        </div>
        <div
          v-for="(panel, index) in panels"
          :key="index"
          class="container-collapsible"
        >
          <div
            @click="toggleCollapse(index)"
            class="collapsible"
            :class="{ active: activePanel === index }"
          >
            {{ panel.title }}
          </div>
          <div
            class="content"
            :style="{
              maxHeight: activePanel === index ? contentHeight + 'px' : '0'
            }"
          >
            <p>{{ panel.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activePanel: null,
      contentHeight: 0,
      panels: [
        {
          title: "Mision",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        },
        {
          title: "Vision",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        }
      ]
    };
  },
  methods: {
    toggleCollapse(index) {
      if (this.activePanel === index) {
        this.activePanel = null;
        this.contentHeight = 0;
      } else {
        this.activePanel = index;
        const content = this.$el.querySelectorAll(".content")[index];
        this.contentHeight = content.scrollHeight;
      }
    }
  }
};
</script>

<style lang="scss">
.collapsible {
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-bottom: 1px black solid;
  #minus {
    display: none;
  }
}

.active,
.collapsible:hover {
  background-color: #555;
}

.content {
  padding: 0rem 18px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.card-container {
  margin: auto;
  display: flex;
  div {
    margin: 3rem;
  }
}

.super-card-container {
  background-image: url("../../../assets/background-1.svg");
  background-size: cover;
  width: 100%;
  margin: auto;
  justify-content: center;
  display: flex;
}

.container-text {
  text-align: start;
  * {
    text-align: start;
  }
}

.container-button {
  margin-top: 2rem;
  display: flex;
}
</style>
