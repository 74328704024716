<template>
  <div class="super-card-container" id="mainProduct">
    <div class="card-container">
      <div>
        <img
          class="img-dimension-for-section"
          src="../../../assets/test-disc-personal.png"
          alt=""
        />
      </div>
      <div class="container-text">
        <img
          style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
          src="../../../assets/line-horizontal.png"
          alt=""
        />
        <span style="color: #658dc0; font-family: montserrat; font-style: italic; font-weight: 600;"> TEST</span>

        <p v-html="getTestDiscPersonal[0].name" style="font-family: Harabara Mais Demo; font-weight: 400; line-height: 49.9px; color: #101D30" class="text-h4 font-weight-black">
        </p>
          <p v-html="getTestDiscPersonal[0].description"  style="color: #686868; font-family: montserrat; font-weight: 500; color: #686868">
          </p>
        <div class="icon-and-text-test-disc-personal" style="margin-left: auto;">
          <img src="../../../assets/calendar-icon.png" alt="" />
          &nbsp;
          <span style="font-weight: 700; font-family: montserrat; line-height: 25px; color: #101D30;"> Accede al test </span>
        </div>
        <div class="icon-and-text-test-disc-personal" style="margin-left: auto;">
          <img src="../../../assets/news-icon.png" alt="" />
          &nbsp;
          <span style="font-weight: 700; font-family: montserrat; line-height: 25px; color: #101D30"> Entérate de nuestros costos </span>
        </div>
        <div class="container-button" style="margin-left: auto;">
          <v-btn
            style="font-size: montserrat;"
            @click="buy(getTestDiscPersonal[0])"
            color="secondary"
          >
            comprar
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    reveal: false
  }),
  computed: {
    ...mapGetters(["getTestDiscPersonal", "getSelectedProduct"])
  },
  methods: {
    ...mapActions(["updateProducts", "selectProduct"]),
    async buy(param) {
      this.selectProduct(param);
      this.$router.push("/checkout");
    }
  },
  async mounted() {
    await Promise.all([this.updateProducts()]);
    await this.getTestDiscPersonal;
  }
};
</script>

<style lang="scss">
.img-dimension-for-section {
  width: 27.625rem;
  height: 25.25rem ;
}

.icon-and-text-test-disc-personal {
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  margin-left: 0rem;
  img {
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.icon-and-text-test-disc-personal:hover {
  text-decoration: underline;
}

.card-container {
  margin: auto;
  display: flex;
  width: 80%;
  div {
    margin: 3rem;
  }
}

.super-card-container {
  background-image: url("../../../assets/background-1.svg");
  background-size: contain;
  width: 100%;
  margin: auto;
  justify-content: center;
  display: flex;
}

.container-text {
  text-align: start;


  * {
    text-align: start;
  }
}

.container-button {
  margin-top: 2rem;
  display: flex;
}
</style>
