<template>
  <div class="super-card-container">
    <div class="card-container">
      <div>
        <img src="../../../assets/nuestra-empresa.svg" alt="" />
      </div>
      <div class="container-text">
        <img
          style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
          src="../../../assets/line-horizontal.png"
          alt=""
        />
        <span style="color: #658dc0"> SOBRE NOSOTROS</span>

        <p class="text-h4 font-weight-black">Nuestra empresa</p>
        <div>
          <p>
            Higher Up Coaching es una empresa líder en coaching dedicada a
            potenciar a individuos y organizaciones para alcanzar su máximo
            potencial. Con un equipo de coaches experimentados y certificados,
            brindamos soluciones de coaching personalizadas adaptadas a las
            necesidades y metas únicas de nuestros clientes. Ya sea que busques
            crecimiento personal o que desees mejorar el rendimiento de tu
            equipo, Higher Up Coaching está aquí para guiarte en tu camino hacia
            el éxito.
          </p>
        </div>
        <div class="container-button">
          <v-btn color="secondary"> Saber Mas </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    reveal: false
  })
};
</script>

<style lang="scss">
.card-container {
  margin: auto;
  display: flex;
  div {
    margin: 3rem;
  }
}

.super-card-container {
  background-image: url("../../../assets/background-1.svg");
  background-size: contain;
  width: 100%;
  margin: auto;
  justify-content: center;
  display: flex;
}

.container-text {
  text-align: start;
  * {
    text-align: start;
  }
}

.container-button {
  margin-top: 2rem;
  display: flex;
}
</style>
