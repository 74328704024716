<template>
  <v-container class="pa-0" fluid>
    <Banner />
    <TestDiscPersonal />
  </v-container>
</template>

<style></style>
<script>
import { mapGetters, mapActions } from "vuex";
import Banner from "./components/Banner.vue";
import TestDiscPersonal from "./components/TestDiscPersonal.vue";
export default {
  components: {
    Banner,
    TestDiscPersonal
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([])
  },
  async mounted() {}
};
</script>
