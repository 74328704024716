<template>
  <div class="super-container">
    <div class="container-subtitle">
      <img
        style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
        src="../../../assets/line-horizontal.png"
        alt=""
      />
      <span style="color: #658dc0"> EQUIPO </span>
      <img
        style="padding: 0.3rem 0.3rem 0.3rem 0.3rem"
        src="../../../assets/line-horizontal.png"
        alt=""
      />
    </div>
    <h1 style="font-size: 3rem">Nuestro equipo</h1>
    <vueper-slides
      class="no-shadow"
      :visible-slides="5"
      :arrows="true"
      :slide-ratio="1 / 4"
    >
      <vueper-slide
        class="slide-item-team"
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        @mouse-enter="changeSelectedSlide(i)"
      />
    </vueper-slides>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ slides[selectedSlideIndex].title }}
        </v-card-title>
        <v-card-text>
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      dialog: false,
      selectedSlideIndex: 0,
      slides: [
        {
          title: "Prueba 1",
          image: require("@/assets/coach1.png"),
          link: this.openDialog
        },
        {
          title: "Prueba 2",
          image: require("@/assets/coach2.png"),
          link: this.openDialog
        },
        {
          title: "Prueba 3",
          image: require("@/assets/coach3.png"),
          link: this.openDialog
        },
        {
          title: "Prueba 4",
          image: require("@/assets/coach4.png"),
          link: this.openDialog
        },
        {
          title: "Prueba 5",
          image: require("@/assets/coach5.png"),
          link: this.openDialog
        }
      ]
    };
  },
  methods: {
    changeSelectedSlide(index) {
      this.selectedSlideIndex = index;
    },
    openSlide() {
      {
        this.dialog = true;
      }
    }
  }
};
</script>

<style lang="scss">
.slide-item-team {
  height: 25rem;
  cursor: pointer;
}
.super-container {
  background-color: white;
  width: 100%;
}
.container-subtitle {
  /* Estilos para el contenedor del subtítulo */
}
</style>
