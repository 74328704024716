<template>
  <o-card-form-layout
    title="Inicio de Sesión"
    :actions="actions"
    :loading="loading"
  >
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="py-0 px-1">
          <o-email-field v-model="user.email" />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-password-field v-model="user.password" />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import authMixin from "@/mixins/authMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "SignIn",
  mixins: [authMixin, messageDialogMixin],
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      actions: [
        { type: "submit", label: "Continuar", handler: this.signIn },
        {
          type: "secondary-left",
          label: "¿Olvidó su contraseña?",
          to: "/forgot-password"
        }
      ],
      loading: false
    };
  },
  computed: {
    ...mapGetters(["isUserAuthenticated"])
  },
  methods: {
    signIn(form) {
      if (form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("signUserIn", {
            email: this.user.email,
            password: this.user.password
          })
          .then(() => {
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.errorDialog(error.message);
          });
        console.log(this.isUserAuthenticated);
        this.$router.push("/");
      }
    }
  },
  async mounted() {
    if (this.isUserAuthenticated) {
      this.$router.push("/");
    }
  }
});
</script>

<style scoped></style>
