<template>
  <v-app id="higherup">
    <v-container class="construction">
      <div>
        <img src="../src/assets/logo3.svg" alt="" />
        <h1>Nos encontramos en construcción, vuelve pronto!</h1>
      </div>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
      processingRequest: false,
      appMenuItems: [
        {
          label: "Perfil",
          icon: "mdi-account",
          handler: this.navigateToProfile,
        },
        {
          label: "Configuración",
          icon: "mdi-settings",
          handler: this.navigateToSettings,
        },
        { label: "Cerrar sesión", icon: "mdi-logout", handler: this.logout },
      ],
      isUserAuthenticated: true,
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    toggleReportMenu() {
      this.showMenu = !this.showMenu;
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    navigateToProfile() {
      this.navigateTo("/profile");
    },
    navigateToSettings() {
      this.navigateTo("/settings");
    },
    navigateToLogin() {
      this.navigateTo("/login");
    },
    navigateToRegister() {
      this.navigateTo("/register");
    },
    logout() {
      // Implement your logout functionality here
      console.log("Logging out...");
    },
    openDialog() {
      this.$refs.messageDialog.open();
    },
    setFloatingAction(action) {
      this.$refs.floatingActionButton.setAction(action);
    },
  },
};
</script>

<style lang="scss">
.construction {
  margin: 0;
  div{
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    img {
      width: 50%;
      margin-bottom: 50px;
    }
    h1 {
      color: white;
    }
  }
  width: 100vw;
  max-width: unset !important;
  height: 100vh;

  background-color: #101d30 !important;
}
.navbarTitle {
  background-image: url("assets/logo4.svg");
  height: 100%;
  width: 200px;
}
.v-app-bar--is-scrolled {
  & .navbarTitle {
    background-image: url("assets/logo3.svg");
  }
  & #line,
  & #secondMenu,
  & .tabs-class {
    display: none !important;
  }
  & .v-toolbar__content {
    background-color: transparent;
  }
}
.v-toolbar {
  background-color: transparent !important;
}
.v-app-bar {
  & .v-app-bar--is-scrolled {
    background-color: transparent;
  }
}
.headerLogo {
  padding: 5px;
  height: 100%;
}
.tabs-custom {
  padding: 0 4px;
  /* Reduce el padding horizontal a 4 píxeles */
  min-width: 0;
  /* Permite que los tabs se ajusten al contenido */
}

.tab-text {
  margin: 0;
  /* Elimina el margen predeterminado */
  font-size: 12px;
  /* Ajusta el tamaño de fuente según tus necesidades */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.tabs-class {
  width: 60%;
}

.politics {
  margin: auto;
  justify-content: flex-end;
  display: flex;
  margin-top: 2.8rem !important;

  p {
    cursor: pointer;
    color: white !important;
  }

  p:hover {
    text-decoration: underline;
  }
}

.copy {
  margin-left: 8rem;
  margin-top: 3rem;
  color: white;
}

.element-info {
  margin-right: 5rem;
  margin-top: 1rem;
}

.color-infos {
  color: #5e5e5e;
}

.icons-width {
  margin-right: 1rem;
  width: 2.5rem;
}

.container-ul {
  margin-top: 4rem !important;
}

.list-ul {
  color: white;

  li {
    cursor: pointer;
    margin: 9px;
  }

  li:hover {
    text-decoration: underline;
  }
}

.container-general-info {
  justify-content: flex-end;
  margin: auto;
  display: flex;
  margin-right: 10rem;

  div {
    margin: 1rem 2rem 0rem 0rem;
  }
}

.card-component {
  margin-left: 10rem;
  bottom: -1.45rem;
}

.background-white {
  background-color: white;
  height: 6rem;
}

.container-background {
  background-color: #101d30;
  margin-top: -15rem;
  height: 25rem;
  width: 100%;
}

.white-background {
  background-color: #ffffff !important;
}

.o-footer > div {
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
}

.icon-network {
  margin: 1rem 1rem 0rem 0rem;
  cursor: pointer;
}

.icon-network-container {
  justify-content: flex-start;
  margin: auto;
  display: flex;
  padding-left: 0%;
}

.o-opacity {
  opacity: 0.9;
}

.blackText {
  color: black;
}

.footer-form {
  margin-top: -119px;
}

.o-menu-item {
  white-space: normal;
}

.v-card__title {
  word-break: normal !important;
}

@media screen {
  .no-screen {
    display: none !important;
  }

  .theme--light.v-application {
    /* background-color: #37526d !important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
  }

  .od-mt-16 {
    margin-top: 16px !important;
  }
}

@media print {
  .no-print,
  nav,
  header,
  footer {
    display: none !important;
  }

  main {
    padding-left: 0rem !important;
  }

  @page {
    size: 8.5in 11in portrait;
    margin: 10mm 10mm 5mm 10mm;
  }
}

.v-messages__message {
  font-weight: 600;
}

.v-slide-group__content,
.v-tabs-bar__content,
.v-toolbar__content {
  background-color: white;
}

p {
  color: black;
  padding-top: 1rem;
}

.transparent-background {
  background-color: transparent !important;
}

.white-background {
  background-color: white !important;
}

.tabs-custom {
  padding: 0 4px;
  min-width: 0;
}

.tab-text {
  margin: 0;
  font-size: 10px;
  white-space: nowrap;
}

.tabs-class {
  width: 60%;
}

/* Asegúrate de que los elementos dentro de v-tabs sean transparentes cuando sea necesario */
.transparent-background >>> .v-tabs-bar {
  background-color: transparent !important;
}

.transparent-background >>> .v-tab {
  background-color: transparent !important;
}
</style>
