<template>
  <div class="servicios-especializados">
    <div class="coachings">
      <div
        v-for="(coaching, index) in getParentCategories"
        :key="index"
        class="coaching-item"
      >
        <div class="d-flex container-title-corporatvo">
          <img
            style="width: 4rem; height: 4rem"
            :src="coaching.image.src"
            alt=""
          />
          <h2 class="title-corporativo">
            {{ coaching.name }}
          </h2>
        </div>
        <div class="line"></div>
        <div class="container-content-1">
          <p class="container-p">
            {{ coaching.description }}
          </p>
          <div class="container-button-center">
            <v-btn style="width: 80%" @click="handleButtonClick(coaching.type)">
              SABER MAS
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="text-general-content">
      <div>
        <img
          style="padding: 0.3rem"
          src="../../../assets/line-horizontal.png"
          alt=""
        />
        <span style="color: #658dc0"> SERVICIOS </span>
      </div>
      <div>
        <h1 style="color: white">Servicios Especializados</h1>
        <p style="color: white">
          Según sea tu objetivo adaptamos nuestros servicios para que logres el
          éxito en todos tus proyectos.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container-general {
  width: 100% !important;
}

.coaching-p {
  width: auto;
}
.container-content-1 {
  justify-content: center;
  margin: auto;
  text-align: start;

  p {
    width: auto;
    margin: auto;
    margin-left: 2rem;
    text-align: start;
    margin-bottom: 1rem;
  }
}

.container-button-center {
  justify-content: center;
  display: flex;
  margin: auto;
}

.coachings {
  display: flex;
}

.servicios-especializados {
  height: 30rem;
  width: 100%;
  background-image: url("../../../assets/servicio-especializado-background.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.text-general-content {
  text-align: start;
  justify-content: start;
  margin: 1rem;
  margin-bottom: 5rem !important;
}

.coaching-item {
  padding: 1rem;
  margin: 1rem;
  width: 18rem;
  height: 18rem;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  background-color: #304a6f;
  border-radius: 0 20px 0 20px;
  border: 1px solid #304a6f;

  .container-button-center button {
    background-color: #658dc0 !important;
    color: white;
  }
  .title-corporativo {
    margin-left: 1rem;
    margin-top: 1rem;
    text-align: start;
    color: white;
  }

  .container-title-corporatvo {
    margin-left: 2rem;
  }

  .line {
    border-bottom: 1px solid rgb(255, 255, 255);
    width: 80%;
    margin: auto;
    justify-content: center;
    display: flex;
    padding: auto;
    margin-top: 1rem;
  }
  .container-p {
    color: white;
  }
  &:hover {
    border: 1px solid #101d30;
    background-color: white;
    .line {
      border-bottom: 1px solid black;
    }
    .container-button-center button {
      background-color: #304a6f !important;
      color: white;
    }

    .container-p {
      color: #686868;
    }
    .title-corporativo {
      color: black;
    }
    width: 20rem;
    height: 20rem;
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      coach: [],
      coachings: [
        {
          type: "corporativo",
          title: "COACHING CORPORATIVO",
          iconSrc: require("../../../assets/coaching-corporativo-icon.svg"),
          content: "Expande tus competencias organizacionales."
        },
        {
          type: "personal",
          title: "COACHING PERSONAL",
          iconSrc: require("../../../assets/coaching-personal-icon.svg"),
          content: "Recibe el test, resultados y plan de desarrollo."
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getParentCategories"])
  },
  methods: {
    ...mapActions(["updateCategories"]),
    handleHover(index) {
      this.isHovered = index;
    },
    handleButtonClick(type) {
      console.log(`Botón de ${type} clickeado`);
    }
  },
  async mounted() {
    await this.updateCategories();
    console.log(this.getParentCategories);
  }
};
</script>
